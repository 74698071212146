
import { defineComponent } from "vue";
import FilterMain from '@/layout/header/partials/filters/FilterMain.vue';

export default defineComponent({
  name: "FilterMainRackViewAchievementList",
  components: { FilterMain },
  setup() {
    return { }
  }
});
